/* Navigation styles */
.megamenu-navigation {
  font-family: $font-family-buttons;

  @include media-breakpoint-up(lg) {
    position: relative;
    width: 100%;
    text-align: right;
  }
  .navigation-menu  {
    .navigation-submenu-hover {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      .navigation-item.hover-navigation-item {
        position: relative;
        z-index: 30;

        &:hover {
          .navigation-submenu-hover {
            display: block;
          }
        }
        .navigation-submenu-hover {
          display: none;
          position: absolute;
          z-index: 25;
          top: 100%;
          @include rem(min-width, 240px);


          &:before {
            content: '';
            bottom: 0;
            box-shadow: 0 4px 12px rgba(0,0,0,0.3);
            filter: blur(6px);
            height: 94%;
            left: 3%;
            position: absolute;
            width: 94%;
            z-index: -1;
          }

          .navigation-submenu-hover-inner {
            box-shadow: 0 4px 8px rgba(0,0,0,0.3);
            border-radius: 3px;
            border: 1px solid #eee;
            @include rem(padding, 10px);
            background-color: $wit;

            .navigation-submenu-item {
              background-color: $wit;
              border-color: #eee;
            }

            .navigation-submenu-link {
              font-family: $font-family-buttons;
              color:$groen;
              &:hover {
                background: $gray-300;
              }
            }
          }

        }
      }
    }
  }

  .navigation-item.has-children {
    .megamenu-btn {
      width: 100%;
      border: 0;
      background-color: transparent;
    }
    &.show {
      outline:0;
      .megamenu-btn {
        font-weight:500;
        outline:0;
      }
      &:active,
      &:focus{
        outline:0;
      }
    }
    @include media-breakpoint-up(lg) {
      .navigation-link {
        display: none;
      }
      .megamenu-btn {
        display: flex;
      }
    }
    @include media-breakpoint-down(md) {
      .megamenu-btn {
        display: none;
      }
    }
  }

  .navigation-menu {
    @include media-breakpoint-up(lg) {
      width: 100%;

      .navigation-item {
        position: static;
        display:inline-block;
        font-weight: 500;
        @include rem(padding-left, 15px);
        @include rem(padding-right, 15px);
        &:hover {
          .navigation-submenu {
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
  }

  .megamenu-dropdown {
    @include rem(max-width, 25px 15px 15px 15px);
    @include rem(padding, 25px 20px 15px 20px);
    @include box-shadow-alt();
    border-radius: 4px;
    top: 42px !important;
    width: 100%;
    transform: none !important;
    border:0;


  }
  .megamenu-menu {
    @include rem(margin-bottom, 20px);
    padding: 0;
  }
  .megamenu-title {
    @include rem(margin-bottom, 20px);
    font-size: 26px;
    font-weight: bold;
    display: inline-block;
  }
  .megamenu-item,
  .megamenu-sublink-item {
    list-style: none;
  }
  .megamenu-link {
    font-weight: bold;
    color: #212529;
  }
  .megamenu-sublink-link {
    color: #212529;
  }
  .counter{
    color:$wit;
    font-family:$font-family-body;
    background: $groen;
    @include rem(min-width, 18px);
  }
}

.move-cart-icon {
    @include media-breakpoint-down(md) {
      float: left;
      &.list-inline-item{
        @include rem(margin-right,20px);
      }
    }
}

.nav-cart-icon{
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 20;
  i{
    color:$primaire-kleur;
  }
  @include rem(width, 52px);
  @include rem(padding, 12px);
}

/* Navigation toggle */
.navigation-toggle {
  float: right;
  border: none;
  background-color: $primaire-kleur;
  color: $white;
  @include rem(padding, 4px 12px 4px 12px);
  @include media-breakpoint-up(lg) {
    display: none;
  }
  &:after {
    @include rem(font-size, 24px);
    position: relative;
    content: "\f0c9";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
  &:focus,
  &:active{
    outline: 0;
    &:focus,
    &:active{
      outline: 0;
    }
  }
  &:hover {
    color: $wit;
  }
}

/* Site Search */
.site-search-mobile-container {
  @include rem(padding, 20px 0 10px 0);
  @include media-breakpoint-down(xs) {
    @include rem(padding, 20px 15px 10px 15px);
  }
}
.site-search-mobile {
  @include rem(padding-right, 95px);
  position: relative;
  .site-search-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* Containers */
.navigation-container {
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
}
.navigation {
  @include media-breakpoint-up(lg) {
    display: block !important;
  }
  @include media-breakpoint-down(sm) {
    @include rem(padding-bottom, 30px);
    border-top: 0;
  }
  @include media-breakpoint-down(xs) {
    padding-bottom: 0;
  }
}

.toggle-menu-title {
  float: left;
  @include rem(margin-top, 7px);
  @include rem(margin-right, 10px);
  font-weight: bold;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

/* Menus */
.navigation-menu,
.navigation-submenu,
.navigation-sub-submenu {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navigation-submenu,
.navigation-sub-submenu {
  @include media-breakpoint-down(xs) {
    border-top: 1px solid #ccc;
  }
  @include media-breakpoint-up(lg) {
    @include rem(width, 220px);
    position: absolute;
    display: block !important;
    z-index: 99;
    left: -1px;
    transition: opacity 0.2s ease-out;
    border: 1px solid #ccc;
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
    background-color: #eee;
  }
}

/* List items */
.navigation-item,
.navigation-submenu-item,
.navigation-sub-submenu-item {
  @include media-breakpoint-down(xs) {
    border-top: 1px solid #ccc;
  }
  @include media-breakpoint-up(lg) {
    border-top: 1px solid #ccc;
  }
  position: relative;
  display: block;
  text-align: left;
  &:first-child {
    border-top: 0;
  }

  &.active{
    .navigation-link {
      span {
        border-bottom:2px solid $primaire-kleur;
      }
      &::before{
        display: block;
        content:url('../img/siersel_leaf_left.png');
        @include rem(max-width, 32px);
        @include rem(margin-right, 7px);
      }
      &::after{
        display: block;
        content:url('../img/siersel_leaf_right.png');
        @include rem(max-width, 32px);
        @include rem(margin-left, 7px);
      }
    }
  }
}

.navigation-item {
  @include media-breakpoint-up(lg) {
    border-top: 0;
    &:hover {
      .navigation-submenu {
        @include rem(width, 220px);
        visibility: visible;
        opacity: 1;
        height: auto;
      }
    }
    &:last-child,
    &:nth-last-child(2) {
      &:hover {
        .navigation-submenu {
          @include rem(width, 220px);
          right: -1px;
          left: auto;
        }
      }
    }
  }
}

/* Links */
.navigation-link,
.navigation-submenu-link,
.navigation-sub-submenu-link {
  @include rem(padding, 10px 5px);
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight:500;
  color:$wit;
  font-family: $font-family-buttons;
  &:active,
  &:focus{
    outline:0;
  }
  &:hover,
  &:focus,
  &:visited
  &:link {
    text-decoration: none;
    color:$wit;
  }
  @include media-breakpoint-down(xs) {
    @include rem(padding, 10px 15px 10px 15px);
  }
}

.navigation-submenu-link,
.navigation-sub-submenu-link {
  @include media-breakpoint-up(lg) {
    @include rem(padding, 8px 20px 8px 20px);
  }
}

.navigation-submenu-link {
  @include media-breakpoint-down(sm) {
    @include rem(padding-left, 35px);
  }
}
.navigation-sub-submenu-link {
  @include media-breakpoint-down(sm) {
    @include rem(padding-left, 65px);
  }
}

/* Dropdown chevrons */
.navigation-chevron {
  @include rem(padding, 10px 16px 10px 16px);
  z-index: 25;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background-color: $gray-300;
  &:after {
    @include rem(font-size, 12px);
    content: "\f077";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
  &.collapsed {
    &:after {
      content: "\f078";
    }
  }
  @include media-breakpoint-down(xs) {
    border-left: 1px solid #ccc;
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

@media print {
  .navigation {
    display: none !important;
  }
}
