/* Back to top */
.back-to-top {
  @include transition(color, 0.5s, ease-in-out);
  @include rem(padding, 6px 13px 10px 13px);
  position: fixed;
  right: 20px;
  bottom: 20px;
  cursor: pointer;
  display: block;
  z-index: 99999;
  background-color: $primaire-kleur;
  color: #fff;
  border-radius: 100%;
  border:0;
  &:focus,
  &:visited,
  &:hover {
    color: #fff;
    background-color: darken($primaire-kleur,5%);
  }
}

@include media-breakpoint-down(md) {
  .back-to-top {
    display: none !important;
  }
}
