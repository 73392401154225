/* ============================================ *
 * Cookiebar
 * ============================================ */
 #cookie-bar {
   background:$grijs-dark;
   height: auto;
   line-height: 24px;
   color: $primaire-kleur;
   text-align: center;
   padding-top: 1rem;
   padding-bottom: 1rem;
   -webkit-box-shadow: 0px 0px 5px 0px rgba(54, 54, 54, 0.15);
   -moz-box-shadow: 0px 0px 5px 0px rgba(54, 54, 54, 0.15);
   box-shadow: 0px 0px 5px 0px rgba(54, 54, 54, 0.15);
 }
 #cookie-bar.fixed {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
 }
 #cookie-bar.fixed.bottom {
   bottom: 0;
   top: auto;
   z-index: 9999;
 }
 #cookie-bar p {
   margin: 0;
   padding: 0;
 }
 #cookie-bar a {
   color:  $primaire-kleur;
   border-radius:60px;
   display: inline-block;
   text-decoration: none;
   padding: 7.5px 15px;
   margin-left: 8px;
   -webkit-transition: 0.3s all;
   transition: 0.3s all;
 }
 #cookie-bar .cb-enable {
   color: $grijs-dark;
   background: $primaire-kleur;
 }
 #cookie-bar .cb-enable:hover {
   background: lighten($primaire-kleur,5%);
 }
 #cookie-bar .cb-policy {
   border:1px solid $primaire-kleur;
   color:$primaire-kleur;
   background: transparent;
 }
 #cookie-bar .cb-policy:hover {
  color: $grijs-dark;
   background: $primaire-kleur;
 }
