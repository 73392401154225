/* Headings */

h1,
h2,
h3,
h4,
h5,
h6, {
  font-family: $font-family-headings;
  color:$wit;
  .fa {
    color: $primaire-kleur;
  }
}
h1 {
  @include rem(font-size, 48px);
  font-weight: 700;
  line-height: 1.1;
}
h2 {
  @include rem(font-size, 46px);
  font-weight: 700;
  line-height: 1.1;
}
h3 {
  @include rem(font-size, 36px);
  font-weight: 700;
  line-height: 1.1;
}
h4 {
  @include rem(font-size, 34px);
  font-weight: 700;
  line-height: 1.1;
}
h5 {
  @include rem(font-size, 28px);
  font-weight: 700;
  line-height: 1.1;
}
h6 {
  @include rem(font-size, 28px);
  font-weight: 700;
  line-height: 1.1;
}

.left-title {
  font-family: $font-family-headings;
  color:$wit;
  @include rem(font-size, 32px);
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0;
  word-break: break-word;
  @include media-breakpoint-down(lg){
    @include rem(font-size, 26px);
  }
  span{
    display: block;
  }
  .fa {
    color: $primaire-kleur;
  }
  &::before{
    display: block;
    content:url('../img/siersel_left.png');
    @include rem(max-width, 64px);
    @include rem(margin-right, 7px);
  }
}
.left-title-sans {
  font-family: $font-family-body;
  color:$wit;
  @include rem(font-size, 24px);
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0;
  word-break: break-word;
  @include media-breakpoint-down(lg){
    @include rem(font-size, 21px);
  }
  .fa {
    color: $primaire-kleur;
  }
  span{
    display: block;
  }
  &::before{
    display: block;
    content:url('../img/siersel_left.png');
    @include rem(max-width, 64px);
    @include rem(margin-right, 7px);
  }
}

.default-title{
  word-break: break-word;
  font-family: $font-family-headings;
  color:$wit;
  @include rem(font-size, 48px);
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: 0;
  letter-spacing: 1px;
  @include media-breakpoint-down(lg){
    @include rem(font-size, 28px);
  }
  .fa {
    color: $primaire-kleur;
  }
  span{
    display: block;
  }
  &::after{
    display: block;
    content:url('../img/siersel_bottom.png');
    @include rem(max-width, 129px);
    @include rem(margin, 7px auto);
  }
}
