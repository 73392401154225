.imagecontent-block{
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  .imagecontent-content-right{
    z-index: 10;
    position: relative;
  }
  .imagecontent-content-left{
    z-index: 5;
    position: relative;
  }
  .imagecontent-container{
     @include rem(padding, 75px 150px);
     @include media-breakpoint-between(md, xl) {
       @include rem(padding, 75px 75px);
     }
     @include media-breakpoint-between(sm, md) {
       @include rem(padding, 75px 50px);
     }
     @include media-breakpoint-between(xs, sm) {
       @include rem(padding, 75px 50px);
     }
  }
  .imagecontent-title{
    @include rem(margin-bottom, 25px);
  }
  .imagecontent-leaf{
    img{
      z-index: 5;
      position: relative;
    }
    &::after{
      content:url('../img/blad_siersel_links.svg');
      width: 440px;
      height: 440px;
      display: block;
      background-size: contain;
      position: absolute;
      right: -42.8%;
      bottom: -190px;
      background-repeat: no-repeat;
      z-index: 0;
      opacity: 0.1;
    }
  }
  .btn{
    @include rem(margin-right, 10px);
    @include rem(margin-top, 15px);
  }
}
