/* Forms */
#MemberLoginForm_LoginForm {
  @include rem(max-width, 500px);
}

#MemberLoginForm_LoginForm_action_doLogin {
  @include rem(margin-bottom, 20px);
  @extend .btn;
}

#ForgotPassword {
  float: left;
  clear: both;
  width: 100%;
}

#Form_InformatieAanvraagFormulier,
#Form_BoekenFormulier {
  label{
    font-weight: 700;
  }

}

form {
  .call-us-header{
    @extend .my-auto;
    @extend .ml-2;
  }
}
