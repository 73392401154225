.element-video {
  .element-video--bg {
    height: 60vh;
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 6px;
    @include media-breakpoint-up(xxl) {
      height: 80vh;
    }
    &::before{
      content:'';
      background: linear-gradient(to right, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 5;
    }
    .element-video--title{
      display: inline-block;
      color:$wit;
      @include rem(font-size, 48px);
      @include rem(margin-bottom, 15px);
      position: relative;
      z-index: 5;
      @include media-breakpoint-down(md) {
        @include rem(font-size, 38px);
      }
      @extend .left-title;
    }
    .element-video--description{
      position: relative;
      z-index: 5;
      color:$wit;
      @include media-breakpoint-down(md) {
        text-shadow: 1px 1px 10px $black;
      }
      @include media-breakpoint-between(xs, sm) {
        @include rem(padding-right, 40px);
      }
    }
  }
}
