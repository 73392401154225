// fonts
@mixin font-face($fontname, $filename: $fontname, $weight: normal, $style: normal) {
 @font-face {
   font-family: $fontname;
   src:url('../fonts/#{$filename}.eot');
   src:url('../fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
     url('../fonts/#{$filename}.ttf') format('truetype');
   font-weight: $weight;
   font-style: $style;
 }
}

@include font-face('Lato', 'lato-v17-latin-700', 700, normal);
@include font-face('Lato', 'lato-v17-latin-regular', 500, normal);
@include font-face('Lato', 'lato-v17-latin-300', 300, normal);

@include font-face('Roboto Slab', 'roboto-slab-v13-latin-300', 300, normal);
@include font-face('Roboto Slab', 'roboto-slab-v13-latin-regular', 500, normal);

@include font-face('Gabriela', 'gabriela-v9-latin-regular', 500, normal);
