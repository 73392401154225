/* Merge Bootstrap and SilverStripe form styles */

#MemberLoginForm_LoginForm {
  input[type="submit"]{
    @extend .btn;
    @extend .btn-secondary;
  }
}

#CheckoutForm_ShippingAddressForm_SilverShop-Checkout-Component-AddressBookShipping_AddressLine2_Holder,
#Form_CreateAddressForm_AddressLine2_Holder {
  display: none!important;
}

div {
  .field {
    @extend .form-group;
  }
}
.middleColumn {
  input.text,
  textarea.textarea {
    @extend .form-control;
  }
}

.element-forms {
  .userformsgroup {
    @include rem(padding, 30px);
  }
}

.Actions {
  .action {
    @extend .btn;
    @extend .btn-success;
  }
  #ForgotPassword {
    @include rem(margin-top, 20px);
  }
}
.btn-toolbar{
  input[type="submit"]{
    @extend .btn;
    @extend .btn-secondary;
  }
}
.optionset {
  list-style: none;
  padding-left: 0;
  margin: 0;
  .checkbox,
  .radio {
    display: inline;
    position: relative;
    top: 1px;
  }
  .odd,
  .even {
    label {
      @include rem(padding-left, 7px);
    }
  }
}
.error.message {
  @extend .form-text;
}
.resetformaction {
  @extend .btn;
}

#popovers,
#tooltips {
  .btn {
    @include rem(margin-right, 5px);
    float: left;
  }
}
button {
  cursor: pointer;
}
#accordion {
  .card-header {
    cursor: pointer;
    padding-right: 40px;
    &:after {
      position: absolute;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f077";
      top: 11px;
      right: 15px;
      font-size: 14px;
    }
    &.collapsed {
      &:after {
        content: "\f078";
      }
    }
  }
}
