/* Header */
.header {
  background-position: bottom center;
  background-repeat: repeat-x;
  @include rem(min-height, 150px);
  @include rem(padding-top, 15px);
  @include rem(padding-bottom, 15px);
}

.logo {
  float: left;
  color: #000;
  @include media-breakpoint-down(sm) {
    @include rem(max-width, 180px);
  }
  &:hover {
    text-decoration: none;
    color: #000;
  }
}

.modal-cart-modifiers {
  .ShippingTaxModifier,
  .ProductTaxModifier{
    color: $gray-600;
    font-weight: 300;
    display: none;
    th {
      font-weight: 300;
    }
  }
}

.site-title,
.site-tagline {
  margin: 0;
}

@media print {
  .logo {
    text-decoration: none !important;
  }
}
